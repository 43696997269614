<template>
  <div class="home">
    <Header></Header>
    <div class="main">
      <h3>欢迎使用</h3>
      <h3>全膝关节置换术软组织平衡辅助决策系统 v1.0.0</h3>
      <div class="tips1">
        本系统可对MRI影像数据进行智能化分析处理，快速准确的识别和测量相关组织并做出相应的判断，输出TKA软组织平衡辅助决策方案。
      </div>
      <div :data-step="1" data-intro="点击选择或拖拽你需要分析的影像数据文件到这里。">
        <div class="uploadBox">
          <div class="upload-demo">
            <input class="ipt" title=" " type="file" multiple="multiple" webkitdirectory @change="handleChange">
            <span class="upload_tip">冠状位T2</span>
            <div class="up"  v-if="List1.length > 0">
              <icon-font
                name="#mdd-yishangchuan"
                :width="65"
                :height="65"
                color="#999999"
              ></icon-font>
              <div class="text">
                拖拽或点击选择替换文件
              </div>
              <div class="text fileName">
                {{list1Title}}
              </div>
            </div>
            <div class="up" v-else>
              <icon-font
                name="#mdd-shangchuan"
                :width="65"
                :height="65"
                color="#999999"
              ></icon-font>
              <div class="text">
                拖拽文件到此处或点击选择手动上传
              </div>
            </div>
          </div>
          <div class="upload-demo" style="margin-left: 20px;">
            <input class="ipt" title=" " type="file" multiple="multiple" webkitdirectory @change="handleChange2" >
            <span class="upload_tip">矢状位T2</span>
            <div class="up"  v-if="List2.length > 0">
              <icon-font
                name="#mdd-yishangchuan"
                :width="65"
                :height="65"
                color="#999999"
              ></icon-font>
              <div class="text">
                拖拽或点击选择替换文件
              </div>
              <div class="text fileName">
                {{list2Title}}
              </div>
            </div>
            <div class="up" v-else>
              <icon-font
                name="#mdd-shangchuan"
                :width="65"
                :height="65"
                color="#999999"
              ></icon-font>
              <div class="text">
                拖拽文件到此处或点击选择手动上传
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="tipBottom">
        <span>*</span>
        <span>请将同一T2位面dicom文件放入文件夹内上传，每个文件夹内最多上传50个dicom文件</span>
      </div>
      <div class="button">
        <div :data-step="3" data-intro="点击这里可以查看样例数据。">
          <el-button type="primary" size="normal" plain @click="lookAnli">
            <icon-font
              class="mr-2"
              name="#mdd-chakan"
              :width="21"
              :height="18"
            ></icon-font>
            查看样例</el-button>
          </div>
        <div :data-step="2" data-intro="选择文件后点击【上传分析】按钮进入下一步开始分析数据。">
          <el-button type="primary" size="normal" :disabled="disabled" @click="submit">上传分析</el-button>
        </div>
      </div>
    </div>
    <div class="dibu">
      <div @click="showGuide">
        <icon-font
          class="mr-2"
          name="#mdd-chakan"
          :width="21"
          :height="18"
        ></icon-font>
        <span>新手引导</span>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '../components/KneeHeader.vue'
import { getInfo, getSample, guide } from '@/api/modules/knee-system'
import intro from '@/utils/intro'
export default {
  name: 'Home',
  components: {
    Header
  },
  data () {
    return {
      List1: [],
      list1Title: '',
      list2Title: '',
      List2: [],
      isDicom: true,
      isDicom2: true,
      disabled: true,
      isShowGuide: false
    }
  },
  mounted () {
    this.getUserInfo()
  },
  watch: {
    List1 (val, old) {
      // console.log(val, this.isDicom, '1')
      if (!this.isDicom && val.length <= 50) {
        if (this.List2.length === 0 && val.length !== 0) {
          this.disabled = false
        } else if (!this.isDicom2) {
          this.disabled = false
        } else {
          this.disabled = true
        }
      } else {
        this.disabled = true
      }
    },
    List2 (val, old) {
      // console.log(val, this.isDicom2, '2')
      if (!this.isDicom2 && val.length <= 50) {
        if (this.List1.length === 0 && val.length !== 0) {
          this.disabled = false
        } else if (!this.isDicom) {
          this.disabled = false
        } else {
          this.disabled = true
        }
      } else {
        this.disabled = true
      }
    }
  },
  methods: {
    async getUserInfo () {
      const { data } = await getInfo()
      if (data.uploadGuide) {
        this.isShowGuide = true
        this.guide()
      } else {
        this.isShowGuide = false
      }
    },
    handleChange (event) {
      const { files } = event.target
      // console.log(files)
      const list = this.List1
      this.List1 = []
      if (files.length === 0) {
        this.List1 = list
      } else {
        Object.keys(files).forEach(v => {
          this.List1.push(files[v])
        })
        this.isDicom = this.List1.some(v => {
          return v.name.indexOf('.DCM') === -1 || ((v.webkitRelativePath.split('/')).length - 1) >= 2
        })
      }
      if (this.isDicom) {
        this.$message.error('文件格式错误')
        this.List1 = []
        this.list1Title = ''
        if (this.List2.length === 0) {
          this.isDicom = true
        } else {
          this.isDicom = false
        }
      } else if (this.List1.length > 50) {
        this.$message.error('文件夹内文件数量超出限制')
        this.List1 = []
        this.list1Title = ''
      } else {
        this.List1.forEach(v => {
          this.list1Title = v.webkitRelativePath.split('/')[0]
        })
      }
    },
    handleChange2 (event) {
      // console.log(file)
      const { files } = event.target
      const list = this.List2
      this.List2 = []
      if (files.length === 0) {
        this.List2 = list
      } else {
        Object.keys(files).forEach(v => {
          this.List2.push(files[v])
        })
        this.isDicom2 = this.List2.some(v => {
          return v.name.indexOf('.DCM') === -1 || ((v.webkitRelativePath.split('/')).length - 1) >= 2
        })
      }
      if (this.isDicom2) {
        this.$message.error('文件格式错误')
        this.List2 = []
        this.list2Title = ''
        if (this.List1.length === 0) {
          this.isDicom2 = true
        } else {
          this.isDicom2 = false
        }
      } else if (this.List2.length > 50) {
        this.$message.error('文件夹内文件数量超出限制')
        this.List2 = []
        this.list2Title = ''
      } else {
        this.List2.forEach(v => {
          this.list2Title = v.webkitRelativePath.split('/')[0]
        })
      }
    },
    guide () {
      if (this.isShowGuide) {
        intro.oncomplete(function () {
          // 点击跳过按钮后执行的事件
          this.isShowGuide = false
        }).onexit(function () {
          // 点击结束按钮后， 执行的事件
          guide().then(res => {})
          this.isShowGuide = false
        }).start()
      }
    },
    lookAnli () {
      getSample().then(res => {
        if (res.code === 0) {
          this.$store.commit('SET_coronalUrls', res.data.coronalUrls)
          this.$store.commit('SET_sagittalUrls', res.data.sagittalUrls)
          this.$router.push({
            path: '/loading',
            query: { id: res.data.id }
          })
        }
      })
    },
    showGuide () {
      this.isShowGuide = true
      this.guide()
    },
    submit () {
      if (!this.disabled) {
        this.$store.commit('SET_GUAN_LIST', this.List1)
        this.$store.commit('SET_SHI_LIST', this.List2)
        this.$router.push('/loading')
      }
    }
  }
}
</script>

<style lang="less" scoped>
.home{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}
.main{
  display: flex;
  flex-direction: column;
  align-items: center;
  h3{
    font-size: 30px;
    color: #555;
    font-weight: 500;
    height: 35px;
    line-height: 35px;
  }
  .tips1{
    height: 14px;
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
    color: #777777;
    margin-top: 25px;
    margin-bottom: 32px;
  }
}
.upload-demo{
  width: 410px;
  height: 280px;
  background: #FBFCFF;
  border: 1px dashed #CCCCCC;
  padding: 20px;
  text-align: left;
  border-radius: 4px;
  position: relative;
  .ipt{
    width: 410px;
    height: 280px;
    opacity: 0;
    position:absolute;
    left: 0;
    top: 0;
}
}
.upload-demo:hover{
  border: 1px dashed #2B7FE2;
  background: rgba(43, 127, 226, 0.06);
}
.uploadBox{
  display: flex;
  .upload_tip{
    font-size: 16px;
    height: 16px;
    line-height: 16px;
    color: #999;
    display: inline-block;
  }
  .up{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 48px;
    .text{
      color: #999;
      font-size: 14px;
      height: 14px;
      line-height: 14px;
      margin-top: 18px;
    }
    .fileName{
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      text-align: center;
    }
  }
}
.tipBottom{
  margin-top: 20px;
  color: #999;
  font-size: 14px;
  height: 14px;
  line-height: 14px;
  span:nth-child(1) {
    color: #F65858;
    margin-right: 5px;
  }
}
.button{
  display: flex;
  align-items: center;
  margin-top: 50px;
  width: 100%;
  justify-content: space-between;
  .el-button--normal{
    width: 410px;
    display: flex;
    align-items: center;
    justify-content: center;
    span{
        display: flex;
        align-items: center;
        justify-content: center;
    }
  }
}
.dibu{
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: #2B7FE2;
  cursor: pointer;
}
</style>
